import styled from 'styled-components';

import { StyledParagraph } from '../../components/Paragraph/styles';
import { StyledTitle } from '../../components/Title/styles';
import { CardColor } from '../../types';

type Props = {
  color: CardColor;
};

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
`;

export const EuLogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  color: #0E1C30;

  .content {
    width: calc(100% - 120px);
  }
`;

export const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
`;

export const Container = styled.div<Props>`
  width: 100%;
  position: relative;
  padding: 120px;
  background-color: ${({ color }: Props): string => (color === 'white' && '#FFFFFF')
    || '#FAFAFA'
};

  ${StyledTitle} {
    font-size: 32px;
    line-height: 48px;
  }

  @media only screen and (max-width: 992px) {
    padding: 80px;
    padding-bottom: 120px;
  }

  @media only screen and (max-width: 576px) {
    padding: 60px 40px;
    padding-bottom: 80px;

    ${StyledTitle} {
      font-size: 24px;
      line-height: 32px;
    }

    ${StyledParagraph} {
      margin-bottom: 32px;
    }

    ${EuLogoContainer} {
      flex-direction: column;

      .content {
        width: 100%;
      }
    }
  }
`;
