import React from 'react';

import { Container, Content, EuLogoContainer, LogoContainer } from './styles';

import Paragraph from '../../components/Paragraph';
import Title from '../../components/Title';
import { CardColor, TextColor } from '../../types';

type Props = {
  logo: JSX.Element;
  title: string;
  titleColor?: TextColor;
  description: string;
  descriptionColor?: TextColor;
  cardColor?: CardColor;
  children?: JSX.Element;
};

const GreenSmeTile = ({
  logo,
  title,
  titleColor = 'blue',
  description,
  descriptionColor = 'darkBlue',
  cardColor = 'grey',
  children
}: Props): JSX.Element => (
  <Container color={cardColor}>
    <LogoContainer>
      { logo }
    </LogoContainer>
    <Content>
      <Title color={titleColor}>
        { title }
      </Title>
      <Paragraph color={descriptionColor}>
        { description }
      </Paragraph>
      <EuLogoContainer>
        { children }
      </EuLogoContainer>
    </Content>
  </Container>
);

export default GreenSmeTile;
