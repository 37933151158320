import { graphql, HeadFC, PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Trans, useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import styled from 'styled-components';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import Nvidia from '../assets/svg/nvidia.svg';
import Panospector from '../assets/svg/panospector.svg';
import Xupp from '../assets/svg/xupp.svg';
import CenterTile from '../sections/CenterTile';
import Footer from '../sections/Footer';
import GreenSmeTile from '../sections/GreenSmeTile';
import HalfTile, { MediumHighlight } from '../sections/HalfTile';
import Hero, { Highlight } from '../sections/Hero';
import IconTile from '../sections/IconTile';
import Layout from '../sections/Layout';

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 60px;
`;

const StyledPanospector = styled(Panospector)`
  max-width: 380px;
  width: 100%;
  height: auto;
`;

const StyledXupp = styled(Xupp)`
  max-width: 122px;
  width: 100%;
  height: auto;
`;

const StyledNvidia = styled(Nvidia)`
  max-width: 60px;
  width: 100%;
  height: auto;
`;

const LanguageItem = styled.a`
  color: #9a9a9a;
  font-size: 24px;
  line-height: 34px;
  text-decoration: none;
  text-transform: uppercase;
  
  &.--active {
    color: #111113;
    font-weight: 600;
  }

  &:active {
    color: #111113;
  }

  &:hover {
    opacity: 0.8;
  }
`;

type Props = {
  location: PageProps['location'];
};

const IndexPage = ({ location }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { languages, changeLanguage } = useI18next();

  const languageIndex = {
    pl: '/',
    en: '/en/'
  };

  const languageList = languages.map((lng) => (
    <li key={lng}>
      <LanguageItem
        className={ languageIndex[lng as keyof typeof languageIndex] === location.pathname ? '--active' : ''}
        href="#"
        onClick={(e): void => {
          e.preventDefault();
          changeLanguage(lng);
        }}
      >
        { lng }
      </LanguageItem>
    </li>
  ));

  return (
    <Layout>
      <>
        <Swiper
          modules={[Pagination, Navigation]}
          navigation
          pagination={{
            clickable: true,
            dynamicBullets: true
          }}
        >
          <SwiperSlide>
            <Hero
              description={t('heroOneDescription')}
              image={(
                <StaticImage
                  alt="Hero image"
                  src="../assets/images/machine.png"
                  style={{ height: '100%', width: '100%' }}
                />
              )}
              languageList={languageList}
              title={(
                <Trans i18nKey="heroOneTitle">
                  <Highlight />
                </Trans>
              )}
            />
          </SwiperSlide>
          <SwiperSlide>
            <Hero
              description={t('heroTwoDescription')}
              image={(
                <StaticImage
                  alt="Hero image"
                  src="../assets/images/food.png"
                  style={{ height: '100%', width: '100%' }}
                />
              )}
              languageList={languageList}
              title={(
                <Trans i18nKey="heroTwoTitle">
                  <Highlight />
                </Trans>
              )}
            />
          </SwiperSlide>
          <SwiperSlide>
            <Hero
              description={t('heroThreeDescription')}
              image={(
                <StaticImage
                  alt="Hero image"
                  src="../assets/images/football.png"
                  style={{ height: '100%', width: '100%' }}
                />
              )}
              languageList={languageList}
              title={(
                <Trans i18nKey="heroThreeTitle">
                  <Highlight />
                </Trans>
              )}
            />
          </SwiperSlide>
        </Swiper>
        <CenterTile
          description={t('aboutUsDescription')}
          title={t('aboutUsTitle')}
          titleColor="darkBlue"
        >
          <ImageContainer>
            <Panospector style={{ height: 24, width: 'auto' }} />
            <Xupp style={{ height: 24, width: 'auto' }} />
          </ImageContainer>
        </CenterTile>
        <HalfTile
          arrow={{ position: 'top-left', color: 'blue' }}
          cardColor="white"
          description={t('industryDescription')}
          image={(
            <StaticImage
              alt="Hero image"
              src="../assets/images/industry.png"
              style={{ height: '100%', width: '100%' }}
            />
          )}
          preTitle={t('industryPreTitle')}
          title={t('industryTitle')}
          titleColor="darkBlue"
        />
        <HalfTile
          arrow={{ position: 'top-right', color: 'grey' }}
          cardColor="grey"
          description={t('sportDescription')}
          direction="row-reverse"
          image={(
            <StaticImage
              alt="Hero image"
              src="../assets/images/sport.png"
              style={{ height: '100%', width: '100%' }}
            />
          )}
          title={t('sportTitle')}
        />
        <HalfTile
          arrow={{ position: 'bottom-right', color: 'blue' }}
          cardColor="blue"
          description={t('medicineDescription')}
          descriptionColor="white"
          image={(
            <StaticImage
              alt="Hero image"
              src="../assets/images/medicine.png"
              style={{ height: '100%', width: '100%' }}
            />
          )}
          title={t('medicineTitle')}
        />
        <CenterTile
          arrow={{ position: 'bottom-left', color: 'grey' }}
          cardColor="white"
          description={t('securityDescription')}
          title={t('securityTitle')}
        />
        <IconTile />
        <HalfTile
          arrow={{ position: 'top-right', color: 'orange' }}
          description={(
            <Trans i18nKey="panospectorDescription">
              <MediumHighlight />
            </Trans>
          )}
          direction="row-reverse"
          image={(
            <StaticImage
              alt="Hero image"
              src="../assets/images/fish.png"
              style={{ height: '100%', width: '100%' }}
            />
          )}
          titleImage={<StyledPanospector />}
        />
        <HalfTile
          arrow={{ position: 'top-left', color: 'green' }}
          description={(
            <>
              <div style={{ marginBottom: 16 }}>
                { t('xuppDescription') }
              </div>
              <StyledNvidia />
            </>
          )}
          image={(
            <StaticImage
              alt="Hero image"
              src="../assets/images/ball.png"
              style={{ height: '100%', width: '100%' }}
            />
          )}
          titleImage={<StyledXupp />}
        />
        <GreenSmeTile
          cardColor="white"
          description={t('greenSmeDescription')}
          logo={(
            <StaticImage
              alt="green SME"
              src="../assets/images/green-sme.png"
              width={320}
            />
          )}
          title={t('greenSmeTitle')}
        >
          <>
            <StaticImage
              alt="green SME"
              src="../assets/images/eu-logo.png"
              width={120}
            />
            <div className="content">
              { /* eslint-disable-next-line max-len */ }
              Funded by the European Union. Views and opinions expressed are however those of the author(s) only and do not necessarily reflect those of the European Union. Neither the European Union nor the European Health and Digital Executive Agency (HADEA) can be held responsible for them.
            </div>
          </>
        </GreenSmeTile>
        <Footer />
      </>
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <title>Panoptes Systems</title>;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
